import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Button from '../layout/button';

const TargetGroupCta = () => {
  const images = useStaticQuery(graphql`
    query {
      easter: file(relativePath: { regex: "/target-group/husvet.png/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 414) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      jack: file(relativePath: { regex: "/target-group/jack.png/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 414) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <div className="py-20">
      <h2 className="uppercase text-center pt-6 pb-16">online megoldások</h2>
      <p className="text-center p-5 max-w-lg mx-auto">
        Online játékaink egyénileg és csapatban is játszhatóak, tanodai/iskolai
        program keretében közösen is élvezhetőek. A korábban számos tematikában
        készített játékaink mindenki számára elérhetőek, itt tudod őket
        kipróbálni:
      </p>
      <div className="container md:flex md:flex-row ">
        <div className="bg-bgfirst md:rounded-xl max-w-lg md:m-5 p-5 pt-10 xl:p-10 xl:pt-16">
          <div className="flex flex-row">
            <h4 className="text-2xl italic font-medium">
              KALANDOR JACK NYOMÁBAN 2.
            </h4>
          </div>
          <div className="mt-5 relative">
            <Img fluid={images.jack.childImageSharp.fluid} alt="Play" />
            <img
              alt="Safe"
              src="/icons/puzzle-orange.svg"
              width="48"
              className="absolute z-10 top-0 right-0 -mr-5 -mt-5"
            />
          </div>
          <p className="mt-3 max-w-lg lg:pr-10">
            Szereted a kalandokat? Szeretnél Indiába menni? Amennyiben igen,
            akkor indítsd el a játékot és mentsd meg Jack bácsit!{' '}
          </p>
          <Button
            to="https://view.genial.ly/5f78529773991b0cf572f1da"
            variant="outlined"
            className="mt-8"
          >
            JÁTÉK KIPRÓBÁLÁSA
          </Button>
        </div>
        <div className="bg-bgsecond md:rounded-xl max-w-lg md:m-5 p-5 pt-10 xl:p-10 xl:pt-16">
          <div className="flex flex-row">
            <h4 className="text-2xl italic font-medium">HÚSVÉTI REJTÉLYEK</h4>
          </div>
          <div className="mt-5 relative">
            <Img fluid={images.easter.childImageSharp.fluid} alt="Play" />
            <img
              alt="Safe"
              src="/icons/puzzle-orange.svg"
              width="48"
              className="absolute z-10 top-0 right-0 -mr-5 -mt-5"
            />
          </div>
          <p className="mt-3 max-w-lg lg:pr-10">
            A Húsvéti Nyuszi mindig hálás azért, ha segítesz neki. Próbáld ki a
            játékot és tegyél jót azzal, hogy segítesz másnak.
          </p>
          <Button
            to="https://view.genial.ly/605fa9aa0138370d9e8486f2 "
            variant="outlined"
            className="mt-8"
          >
            JÁTÉK KIPRÓBÁLÁSA
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TargetGroupCta;
