import React from 'react';
import CommentYellow from '../company/comment-yellow';
import CommentGreen from '../company/comment-green';

const TargetGroupReferences = () => {
  return (
    <div className="py-20">
      <CommentYellow description="Nagyon király program volt, hihetetlen, hogy milyen gyorsan vége lett. Soha nem gondoltam volna, hogy ennyire jó csapatban játszani." />
      <CommentGreen
        description="Szeretnéd, hogy hozzátok is elmenjünk az iskolába/tanodába/gyermekotthonba? Mentor/önkéntes vagy a célcsoportunknál, vagy ismersz olyan fiatalt, akiknek hasznos lenne egy online készségfejesztő játék? Vedd fel velünk a kapcsolatot és megbeszéljük a részleteket."
        buttonText="LÉPJ KAPCSOLATBA VELÜNK!"
        buttonHref="/kapcsolat"
      />
    </div>
  );
};

export default TargetGroupReferences;
