import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const TargetGroupHero = () => {
  const images = useStaticQuery(graphql`
    query {
      guys: file(relativePath: { regex: "/target-group/illustration.jpg/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 414) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      group: file(relativePath: { regex: "/about/about_group.jpg/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 414) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <div className="container mt-20 mb-20 md:mt-40">
      <h2 className="uppercase text-center mb-16">célcsoportnak</h2>
      <div className="w-full lg:flex relative my-20">
        <div className="">
          <div className="max-w-md mx-auto pl-5 pb-5 pr-5 xl:mr-24 xl:ml-16 xl:mt-10">
            <h4 className="text-2xl italic font-medium mb-6">
              Hátrányos helyzetű fiatalok
            </h4>
            <p className="mb-5 text-base text-left leading-loose px-1">
              A Fortély-sátor Alapítvány célcsoportja a{' '}
              <span className="font-bold">
                10-18 éves, szociálisan hátrányos helyzetű fiatalok
              </span>{' '}
              csoportja, elsősorban azok, akik már részt vesznek valamilyen
              tanodaprogramban. A célcsoportot ezen belül is két nagyobb
              korosztályra bontottuk: 10-14 és 14-18 éves fiatalok csoportjára,
              így minden korosztálynak a megfelelő típusú játékot tudjuk
              kínálni.
            </p>
            <p className="mb-10 text-base text-left leading-loose px-1">
              Az korosztálytól függetlenül igaz, hogy ezek a gyerekek
              <span className="font-bold">
                {' '}
                az iskolában szinte sosem élnek át sikerélményt,
              </span>{' '}
              iskolai közegben főként negatív tapasztalataik vannak. Az iskolán
              kívül nem mozdulnak ki a komfortzónájukból, nincs lehetőségük
              próbára tenni saját képességeiket.
            </p>
          </div>
        </div>
        <div className="lg:w-1/2 overflow-hidden">
          <div className="max-w-md lg:max-w-lg lg:pl-20  mx-auto relative">
            <Img
              className=""
              fluid={images.guys.childImageSharp.fluid}
              alt="Play"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TargetGroupHero;
