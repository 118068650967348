import React from 'react';

const MobilGame = () => {
  return (
    <div className="bg-bgsecond">
      <h2 className="uppercase text-center pt-20 mb-16">MOBIL JÁTÉKAINK</h2>
      <div className="container md:flex justify-center pb-20">
        <div className="m-5 md:w-1/4 bg-white rounded-lg">
          <img
            alt="Idézet"
            src="/icons/pirate-flag.svg"
            width="106"
            className="mx-auto mt-8 mb-8"
          />
          <h3 className="uppercase text-center mb-5">FORTÉLY-SÁTOR JR</h3>
          <p className="text-center text-sm px-8 pb-8 md:pb-0 md:mb-10">
            A Fortély-sátor JR a fiatalabb, 10-14 éves korosztálynak szól, a
            játék során a kalózok kincsét kell megtalálni.
          </p>
        </div>
        <div className="m-5 md:w-1/4 bg-white rounded-lg">
          <img
            alt="Idézet"
            src="/icons/globe.svg"
            width="106"
            className="mx-auto mt-8 mb-8"
          />
          <h3 className="uppercase text-center mb-5">E(CO)SCAPE</h3>
          <p className="text-center text-sm px-8 pb-8 md:pb-0 ">
            Az E(co)scape elnevezésű játékot inkább 14 éves kortól felfelé
            javasoljuk, ennek fókuszában a környezettudatosság jelenik meg.
          </p>
        </div>
        <div className="m-5 md:w-1/4 bg-white rounded-lg">
          <img
            alt="Idézet"
            src="/icons/settings.svg"
            width="106"
            className="mx-auto mt-8 mb-8"
          />
          <h3 className="uppercase text-center mb-5">HELYSZÍNHEZ IGAZÍTHATÓ</h3>
          <p className="text-center text-sm px-8 pb-8 md:pb-0 ">
            A harmadik játékunkat már ajánljuk 10 éves kortól is, ennek a
            kerettörténete igény szerint a helyszínhez igazítható.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobilGame;
