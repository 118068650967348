import React from 'react';
import Layout from '../components/layout';
import TargetGroupHero from '../components/target-gruop/target-group-hero';
import MobilGame from '../components/target-gruop/mobil-game';
import TargetGroupCta from '../components/target-gruop/target-group-cta';
import TargetGroupReferences from '../components/target-gruop/target-group-reference';
import Games from '../components/company/games';

const CelcsoportnakPage = () => {
  return (
    <Layout
      seo={{
        title: 'Célcsoportnak',
        description: 'A Fortély-csapat'
      }}
    >
      <TargetGroupHero />
      <Games />
      <MobilGame />
      <TargetGroupCta />
      <TargetGroupReferences />
    </Layout>
  );
};

export default CelcsoportnakPage;
